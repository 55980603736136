import React, { useState } from "react";
import axios from "axios";

const FormComponent = () => {
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    message: "",
    file: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  function generateHtmlTable(data) {
    // Initialize the HTML string with the table header
    let html = `
    <table border="1" style="border-collapse: collapse; width: 100%;">
        <tbody>
    `;

    html += `
            <tr>
                <td>Name</td>
                <td>${data.get("name") || ""}</td>
            </tr>
            <tr>
                <td>Email</td>
                <td>${data.get("email") || ""}</td>
            </tr>
            <tr>          
                <td>Phone</td>  
                <td>${data.get("phone") || ""}</td>
            </tr>
            <tr>
                <td>Company</td>
                <td>${data.get("company") || ""}</td>
            </tr>
            <tr>
                <td>Message</td>
                <td>${data.get("message") || ""}</td>
            </tr>
        `;

    // Close the tbody and table tags
    html += `
        </tbody>
    </table>
    `;

    return html;
  }

  function resetForm() {
    setFormData({
      name: "",
      email: "",
      phone: "",
      company: "",
      message: "",
      file: null,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    const form = new FormData();
    form.append("name", formData.name);
    form.append("email", formData.email);
    form.append("phone", formData.phone);
    form.append("company", formData.company);
    form.append("message", formData.message);
    form.append("file", formData.file);
    form.append("html", generateHtmlTable(form));
    axios
      .post("https://locuscadworks.com/send_mail.php", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((data) => {
        data = data.data;
        if (data.status === "success") {
          alert(data.message);
          resetForm();
        } else {
          alert(data.message);
        }        
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred while sending the email.");
      })
      .finally(() => setLoader(false));
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="w-full mb-4">
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          className="block w-full rounded-md border-0 py-2.5 px-4 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          required
        />
      </div>
      <div className="w-full mb-4">
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          className="block w-full rounded-md border-0 py-2.5 px-4 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          required
        />
      </div>
      <div className="w-full mb-4">
        <input
          type="text"
          name="phone"
          placeholder="Phone Number"
          value={formData.phone}
          onChange={handleChange}
          className="block w-full rounded-md border-0 py-2.5 px-4 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          required
        />
      </div>
      <div className="w-full mb-4">
        <input
          type="text"
          name="company"
          placeholder="Company Name"
          value={formData.company}
          onChange={handleChange}
          className="block w-full rounded-md border-0 py-2.5 px-4 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          required
        />
      </div>
      <div className="w-full mb-4">
        <textarea
          name="message"
          rows={3}
          placeholder="Message"
          value={formData.message}
          onChange={handleChange}
          className="block w-full rounded-md border-0 py-2.5 px-4 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          required
        />
      </div>
      <div className="w-full mb-4">
        <p className="text-sm mb-2">Attach Document / Files</p>
        <label className="block bg-white p-4 rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
          <span className="sr-only">Choose profile photo</span>
          <input
            type="file"
            name="file"
            onChange={handleChange}
            className="block w-full text-sm text-gray-500 file:me-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-gray-600 file:text-white hover:file:bg-gray-700 file:disabled:opacity-50 file:disabled:pointer-events-none dark:file:bg-gray-500 dark:hover:file:bg-gray-400"
          />
        </label>
      </div>
      <div className="w-full text-center">
        <button className="rounded-md bg-green px-10 py-3 text-sm font-semibold text-black shadow-sm hover:bg-green focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" disabled={loader}>
          {loader ? "Loading..." : "Submit"}
        </button>
      </div>
    </form>
  );
};

export default FormComponent;
