import React from 'react';
import Header from './Header';
import Footer from './Footer';

const careersData = [
  {
    id: 1,
    title: 'CAD Draftsmen',
    location: 'Nashik, Maharashtra ',
    description: 'An assisting role as professional cad draftsmen.',
  },
  {
    id: 2,
    title: 'Civil Engineer',
    location: 'Nashik, Maharashtra',
    description: 'Join our engineering teams to provide smart solutions that make a difference.',
  },
  {
    id: 2,
    title: 'Industrial Designer',
    location: 'Nashik, Maharashtra',
    description: 'Exciting opportunity for a creative individual in UI/UX design and graphic arts, offering diverse and versatile projects.',
  },
];

const CareerListing = () => {
  return (
    <>
    <Header/>
    <div>
      <div className="career-bg text-white py-6 px-4 min-h-[500px] flex items-center justify-start">
       
        <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 '>
          <h1 className="text-4xl font-bold mb-2">Join Us at Locus</h1>
          <p className="text-lg">Explore exciting career opportunities.</p>
        </div>
      </div>
      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-20'>
        <h2 className="text-2xl font-bold mb-4">Current Job Openings</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {careersData.map((career) => (
            <div key={career.id} className="bg-white shadow-md p-6 rounded-lg">
              <h3 className="text-xl font-semibold mb-2">{career.title}</h3>
              <p className="text-sm text-gray-600 mb-2">{career.location}</p>
              <p className="text-base min-h-[72px]">{career.description}</p>
              <a href='/contactus' className="inline-block mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                Apply Now
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default CareerListing;
