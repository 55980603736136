import React from 'react'
import Header from '../Header'
import DropdownMenu from '../Dropdown'
import LandSurvey2 from '../assets/images/land-survey-2.png'
import Footer from '../Footer'


const DesignService = () => {
  return (
    <div className="min-h-full">
    <Header />
    <div className='w-full DesignService min-h-[400px]'>
      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex items-center justify-center text-white min-h-[400px]'>
        <h1 className='md:text-7xl text-4xl font-bold'>Design Services</h1>
      </div>
    </div>
    <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-20'>
    <div className='grid md:grid-cols-4 grid-cols-3 gap-6'>
      <div className='md:block hidden'>
        <DropdownMenu/>
        </div>

        <div className='col-span-3'>
          <div className='mb-10'>
            <h2 className='text-3xl font-semibold text-green mb-4'>Design Services</h2>
            <p>We offer a diverse range of design services aimed at elevating your brand and enhancing user experience across various platforms.we are committed to delivering exceptional design solutions that resonate with your audience and drive business growth. Let us partner with you to turn your design aspirations into reality.

            </p>
          </div>

          <img src={LandSurvey2} alt='' className='mb-6'/>

          <h3 className='text-xl font-semibold mb-5'>Services we provide in Designing Services</h3>
          <div className='grid grid-cols-2 gap-5 mb-10'>
              <ul className='list-disc list-inside'>
              <li className='text-sm leading-loose mb-3'><b>Interior Design:</b> Transforming interior spaces into functional and aesthetically pleasing environments is our forte. Whether it's residential, commercial, or hospitality spaces, we create designs that reflect your style and optimize the use of space.</li>
              <li className='text-sm leading-loose mb-3'><b>Graphic Design:</b> Our team of skilled graphic designers brings your brand to life through captivating visuals and effective communication. From logo design to marketing collateral, we ensure consistency and creativity in every aspect of your brand identity.
              </li>
              <li className='text-sm leading-loose mb-3'><b>Industrial Design:</b> We specialize in industrial design, combining form and function to create products that are both visually striking and user-friendly. From conceptualization to prototyping, we guide you through every stage of the design process to bring your ideas to fruition.</li>
              <li className='text-sm leading-loose mb-3'><b>Product Design:</b> With a keen understanding of market trends and consumer preferences, we develop innovative product designs that resonate with your target audience. Our holistic approach encompasses ideation, prototyping, and testing to ensure the success of your product in the market.</li>
              <li className='text-sm leading-loose mb-3'><b>Packaging Design:</b> Packaging plays a crucial role in brand perception and consumer engagement. We design packaging solutions that not only protect and showcase your products but also captivate attention and reinforce brand identity on the retail shelf.</li>
              <li className='text-sm leading-loose mb-3'><b>Branding:</b> Building a strong brand presence is essential for differentiation and long-term success. Our branding services encompass strategy development, logo design, brand guidelines, and implementation across various touchpoints to create a cohesive and memorable brand experience.</li>
             
              </ul>

          </div>

        

          <div className='w-full py-10 md:px-20 px-4 bg-green rounded-2xl text-center'>
              <h3 className='text-2xl mb-3 font-semibold'>Have a concept in Mind?</h3>
              <p className='mb-5 text-sm'>Share with us, to bring it in real world!</p>
              <div className='w-full flex items-center justify-center gap-6 '>
                <a href='/contactus' className='px-6 py-3 text-black bg-white font-semibold rounded-md'>Contact Us</a>
                <a href='/contactus' className='px-6 py-3 text-black bg-transparent border border-black font-semibold rounded-md'>Write Us</a>
              </div>
            </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
  )
}

export default DesignService