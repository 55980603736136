import React, { useState } from 'react'
import Header from '../Header'

import LandSurvey2 from '../assets/images/land-survey-2.png'
import Footer from '../Footer'
import DropdownMenu from '../Dropdown'

function GeometryServices() {



  return (
    <div className="min-h-full">
      <Header />
      <div className='w-full GeometryServices min-h-[400px]'>
        <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex items-center justify-center text-white min-h-[400px]'>
          <h1 className='md:text-7xl text-4xl font-bold'>Geometry Design Services</h1>
        </div>
      </div>
      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-20'>
      <div className='grid md:grid-cols-4 grid-cols-3 gap-6'>
      <div className='md:block hidden'>
        <DropdownMenu/>
        </div>

          <div className='col-span-3'>
            <div className='mb-10'>
              <h2 className='text-3xl font-semibold text-green mb-4'>Geometry Design</h2>
              <p>Most of the subdivision plans of the residential projects of 500 to 1000 acres feeting the lots for different family types and sizes is now simple using cad technique. Still we have to check the geometry as per the road speed criteria for radius of the center-line, Culdisac, easements and all other county requirements. 
Adding the right of way is one part of the geometry design but adjusting the lots inside the area remaining and open spaces while maintaining the lot width and length is very important as well.

              </p>
            </div>

            <img src={LandSurvey2} alt='' className='mb-6'/>

            <h3 className='text-xl font-semibold mb-5'>Services we provide in Geometry Design</h3>
            <div className='grid grid-cols-2 gap-5 mb-10'>
                <ul className='list-disc list-inside'>
                  <li className='text-sm leading-loose'>Subdivision Platting</li>
                  <li className='text-sm leading-loose'>Residential & Commercial Plot plans</li>
                  <li className='text-sm leading-loose'>Meets & Bound Drafting</li>
                  <li className='text-sm leading-loose'>Deed Writing</li>
                  <li className='text-sm leading-loose'>Pre-Calc.</li>
                  <li className='text-sm leading-loose'>Plat Drafting & Error Eliminations</li>
               
                </ul>

            </div>

          

            <div className='w-full py-10 md:px-20 px-4 bg-green rounded-2xl text-center'>
              <h3 className='text-2xl mb-3 font-semibold'>Have a concept in Mind?</h3>
              <p className='mb-5 text-sm'>Share with us, to bring it in real world!</p>
              <div className='w-full flex items-center justify-center gap-6 '>
                <a href='/contactus' className='px-6 py-3 text-black bg-white font-semibold rounded-md'>Contact Us</a>
                <a href='/contactus' className='px-6 py-3 text-black bg-transparent border border-black font-semibold rounded-md'>Write Us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>







  )
}

export default GeometryServices