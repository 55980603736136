import React, { useState } from 'react';

const DropContent = [
  {
    name: 'Land Surveying',
    sub: [
      { name: 'Location Survey' },
      { name: 'Title / Mortgage Survey' },
      { name: 'ALTA/NSPS Survey' },
      { name: 'Topographic Survey' },
      { name: 'Right of way Survey' },
    ],
  },
  {
    name: 'Land Development',
    sub: [
      { name: 'Residential Development' },
      { name: 'Commercial Development' },
      { name: 'Right of way Development' },
      { name: 'Intersection Development' },
      { name: 'Airport Development' },
      { name: 'Farm Land Development' },
    ],
  },
  {
    name: 'As-Built',
    sub: [
      { name: 'Commercial As-built' },
      { name: 'Residential As-built' },
    ],
  },
  {
    name: 'Geometry Design',
    sub: [
      { name: 'Subdivision Platting' },
      { name: 'Residential & Commercial Plot plans' },
      { name: 'Meets & Bound Drafting' },
      { name: 'Deed Writing' },
      { name: 'Pre-Calc.' },
      { name: 'Plat Drafting & Error Eliminations' },
    ],
  },
  {
    name: 'Architectural Services',
    sub: [
      { name: '2d plan and elevation' },
      { name: 'Working drawings' },
      { name: 'Technical drawings' },
      { name: 'Presentation layout' },
      { name: 'Floor plan' },
      { name: 'Site plan' },
      { name: 'Isometric, axonometric view' },
      { name: 'Material estimation' },
    ],
  },
  {
    name: 'Design Services',
    sub: [
      { name: 'Interior Design' },
      { name: 'Graphic Design' },
      { name: 'Industrial Design' },
      { name: 'Product Design' },
      { name: 'Packaging Design' },
      { name: 'Branding' },
    ],
  },
  {
    name: '3D Visualization',
    sub: [
      { name: '2d to 3d Modeling' },
      { name: 'Sketch-up Modeling' },
      { name: '3d rendered views' },
      { name: '3d walkthrough animation' },
      { name: 'Brochure views' },
    ],
  },
  {
    name: 'CAD Services',
    sub: [
      { name: 'PDF to CAD conversions' },
      { name: 'Raw sketches Conversions' },
      { name: 'Electrical 2D Drawings' },
    ],
  },
  {
    name: 'Construction Drawing',
    sub: [
      { name: 'Utility Staking' },
      { name: 'Construction Staking' },
      { name: 'Foundation Location' },
      { name: 'Features Staking (i.e. Curb, Sidewalk, Wall etc.)' },
      { name: 'Heat Maps' },
      { name: 'Deviation Drawings' },
      { name: 'Construction Drawings' },
      { name: 'Fence & Wall Staking' },
    ],
  },
  {
    name: 'Geo-Spatial Services',
    sub: [
      { name: 'Point cloud Creation' },
      { name: 'Ortho Mosaic (GeoTiff)' },
      { name: 'Data Extraction from Point cloud' },
      { name: 'Aerial Survey (DJI-Tera, Pix 4D mapper)' },
      { name: 'LIDAR Survey (Trimble, Lider)' },
    ],
  },
  {
    name: 'BIM Services',
    sub: [
      { name: 'Content to be there' },
    ],
  },
];

const DropdownMenu = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleDropdown = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className='p-4 border border-gray-100 sticky top-4'>
      {DropContent.map((item, index) => (
        <div key={index} className="relative mb-4">
          <div
            className="title block font-semibold text-base text-black cursor-pointer"
            onClick={() => toggleDropdown(index)}
          >
            {item.name}
          </div>
          {openIndex === index && item.sub && (
            <div className="content bg-white overflow-hidden transition-all duration-300 ease-in-out">
              <ul className='list-disc list-inside'>
                {item.sub.map((subItem, subIndex) => (
                  <li className='text-sm leading-loose' key={subIndex}>{subItem.name}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default DropdownMenu;
 