import React from 'react'
import Header from '../Header'
import DropdownMenu from '../Dropdown'
import LandSurvey2 from '../assets/images/land-survey-2.png'
import Footer from '../Footer'


const D3Visualising = () => {
  return (
    <div className="min-h-full">
    <Header />
    <div className='w-full D3Visualising min-h-[400px]'>
      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex items-center justify-center text-white min-h-[400px]'>
        <h1 className='md:text-7xl text-4xl font-bold'>3d Visualization Services</h1>
      </div>
    </div>
    <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-20'>
      <div className='grid md:grid-cols-4 grid-cols-3 gap-6'>
        <div className='md:block hidden'>
        <DropdownMenu/>
        </div>

        <div className='col-span-3'>
          <div className='mb-10'>
            <h2 className='text-3xl font-semibold text-green mb-4'>3d visualization Services</h2>
            <p>We offer an immersive 3D visualization solutions. With a suite of advanced software and unparalleled expertise, we combine technical expertise with artistic vision to deliver 3D visualization solutions that exceed expectations.  we bring your ideas to life with breathtaking realism and precision.
            </p>
          </div>

          <img src={LandSurvey2} alt='' className='mb-6'/>

          <h3 className='text-xl font-semibold mb-5'>Services we provide in Designing Services</h3>
          <div className='grid md:grid-cols-3 grid-cols-1 gap-5 mb-10'>
              <ul className='list-inside col-span-2'>
              <li className='text-sm leading-loose mb-3'><b>2D to 3D Modeling:</b> Seamlessly transition from 2D concepts to dynamic 3D models with our expert modeling services.</li>
              <li className='text-sm leading-loose mb-3'><b>Sketch-up Modeling:</b> Harness the power of Sketch-Up to create detailed and accurate 3D models that embody your vision.</li>
              <li className='text-sm leading-loose mb-3'><b>3D Rendered Views:</b> Visualize your designs in photorealistic detail with our rendering services, powered by V-ray and other cutting-edge software.</li>
              <li className='text-sm leading-loose mb-3'><b>3D Walkthrough Animation:</b> Take a virtual tour of your project with our immersive walkthrough animations, crafted using Lumion and Auto desk Maya</li>
              <li className='text-sm leading-loose mb-3'><b>Brochure Views:</b> Showcase the highlights of your design with captivating brochure views, perfect for marketing materials and client presentations.</li>
              </ul>

          </div>

          <h3 className='text-xl font-semibold mb-3'>Software Expertise</h3>
          <p className='text-base mb-5'>Our team is proficient in a range of industry-leading software, including:</p>
          <div className='grid md:grid-cols-2 gap-5 mb-10'>
              <ul className='list-disc list-inside'>
                <li className='text-sm leading-loose'>Auto-CAD</li>
                <li className='text-sm leading-loose'>Sketch-up</li>
                <li className='text-sm leading-loose'>Revit</li>
                <li className='text-sm leading-loose'>Auto desk Maya</li>
                <li className='text-sm leading-loose'>Lumion</li>
                <li className='text-sm leading-loose'>V-ray</li>
                <li className='text-sm leading-loose'>Enscape</li>
                <li className='text-sm leading-loose'>3ds Max</li>
              </ul>

          </div>

        

          <div className='w-full py-10 md:px-20 px-4 bg-green rounded-2xl text-center'>
              <h3 className='text-2xl mb-3 font-semibold'>Have a concept in Mind?</h3>
              <p className='mb-5 text-sm'>Share with us, to bring it in real world!</p>
              <div className='w-full flex items-center justify-center gap-6 '>
                <a href='/contactus' className='px-6 py-3 text-black bg-white font-semibold rounded-md'>Contact Us</a>
                <a href='/contactus' className='px-6 py-3 text-black bg-transparent border border-black font-semibold rounded-md'>Write Us</a>
              </div>
            </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
  )
}

export default D3Visualising