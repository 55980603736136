import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';

import App from './App';
import LandSurvey from './services/LandSurvey';
import Contact from './ContactUs';
import CareerListing from './Career';
import LandDevelopment from './services/LandDevelopement';
import AsBuilt from './services/AsBuilt';
import GeometryServices from './services/GeometryServices';
import ArchService from './services/ArchService';
import DesignService from './services/DesignService';
import D3Visualising from './services/D3Visualising';
import CadServices from './services/CadServices';
import GeoSpatial from './services/GeoSpatial';
import ConstructionService from './services/ConstrucationService';

const Root = () => {
  return (
    <React.StrictMode>
      <Router>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/landsurvey" element={<LandSurvey />} />
          <Route path="/land-development" element={<LandDevelopment />} />
          <Route path="/as-built" element={<AsBuilt />} />
          <Route path="/geometry" element={<GeometryServices />} />
          <Route path="/arch-service" element={<ArchService />} />
          <Route path="/design-service" element={<DesignService />} />
          <Route path="/3d-Visualization" element={<D3Visualising />} />
          <Route path="/cad-services" element={<CadServices />} />
          <Route path="/construction" element={<ConstructionService />} />
          <Route path="/geo-spatial" element={<GeoSpatial />} />
          
          <Route path="/contactus" element={<Contact />} />
          <Route path="/career" element={<CareerListing />} />
    
          {/* Add more routes as needed */}
        </Routes>
      </Router>
    </React.StrictMode>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));
