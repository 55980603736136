import React from 'react'
import Header from '../Header'

import LandDevser from '../assets/images/land-dev-ser.png'
import Footer from '../Footer'
import DropdownMenu from '../Dropdown'

const DropContent = [
  { name: 'Land Surveying', },
  { name: 'Land Development', },
  { name: 'As-Built', },
  { name: 'Geometry Design', },
  { name: 'Architectural Services', },
  { name: ' Design Services', },
  { name: ' 3D Visualization', },
  { name: '  CAD Services', },
  { name: '  Construction Drawing', },
  { name: '  Geo-Spatial Services',},
  { name: '  BIM Services ',},

]


function LandDevelopment() {
  return (
    <div className="min-h-full">
      <Header />
      <div className='w-full LandDevelopment min-h-[400px]'>
        <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex items-center justify-center text-white min-h-[400px]'>
          <h1 className='md:text-7xl text-4xl font-bold'>Land Development Services</h1>
        </div>
      </div>
      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-20'>
      <div className='grid md:grid-cols-4 grid-cols-3 gap-6'>
      <div className='md:block hidden'>
        <DropdownMenu/>
        </div>

          <div className='col-span-3'>
            <div className='mb-10'>
              <h2 className='text-3xl font-semibold text-green mb-4'>Land Development Services</h2>
              <p>The development phase is pivotal, demanding a thorough assessment of potential failures from diverse perspectives. During this stage, functionality takes precedence over aesthetics. Our concentration is on sculpting the land to not only meet future demands but also effectively serve the needs of users.
Our fundamental approach to development entails visualizing a scenario where modifications to the established development are impractical, expensive, and potentially disastrous. This foresight underscores our commitment to ensuring that the development is not only visually appealing but also sustainable and resilient in the face of potential challenges.

              </p>
            </div>
            <div className='mb-10'>
            <h3 className='text-xl font-medium'>How Does It Work?</h3>
            <ol className='list-decimal list-inside'>
              <li>Gathering input and details of the project require to complete.</li>
              <li>Finalize the boundary to tie in and arrange the drawings accordingly.</li>
              <li>Creating documentation of the clients requirement of the project.</li>
              <li>Study all the City/County and state rules and regulations.</li>
              <li>Preparation of the working chart and finalizing the deadlines.</li>
            </ol>
            </div>

            <img src={LandDevser} alt='' className='mb-6'/>

            <h3 className='text-xl font-semibold mb-5'>Expertise in different task</h3>
            <div className='grid grid-cols-2 gap-5 mb-10'>
                <ul className='list-disc list-inside'>
                  <li className='text-sm leading-loose'>Simplest sheet setup.</li>
                  <li className='text-sm leading-loose'> Gading of different terrane sites.</li>
                  <li className='text-sm leading-loose'> Expertise in cost effective design.</li>
                  <li className='text-sm leading-loose'> Very good in utilizing the space of the property.</li>
                </ul>

                <ul className='list-disc list-inside'>
                  <li className='text-sm leading-loose'>Attractive & sustainable design.</li>
                  <li className='text-sm leading-loose'>Design of road profile using DOT standards.</li>
                  <li className='text-sm leading-loose'> Dynamic Utility and cost effective design.</li>
                  <li className='text-sm leading-loose'> Corridor modeling</li>
                  <p className='text-sm leading-loose'> And continuously counting . . .</p>
                </ul>
            </div>

            <h3 className='text-xl font-semibold mb-5'>Land Development services we offer : </h3>
            <div className='grid grid-cols-2 gap-5 mb-10'>
                <ul className='list-disc list-inside'>
                  <li className='text-sm leading-loose'>Residential Development</li>
                  <li className='text-sm leading-loose'> Commercial Development</li>
                  <li className='text-sm leading-loose'> Right of way Development</li>
                  <li className='text-sm leading-loose'> Intersection Development</li>
                </ul>

                <ul className='list-disc list-inside'>
                  <li className='text-sm leading-loose'>Airport Development</li>
                  <li className='text-sm leading-loose'> Farm Land Development</li>
                  <p className='text-sm leading-loose'> And continuously counting . . .</p>
                </ul>
            </div>

            <div className='w-full py-10 md:px-20 px-4 bg-green rounded-2xl text-center'>
              <h3 className='text-2xl mb-3 font-semibold'>Have a concept in Mind?</h3>
              <p className='mb-5 text-sm'>Share with us, to bring it in real world!</p>
              <div className='w-full flex items-center justify-center gap-6 '>
                <a href='/contactus' className='px-6 py-3 text-black bg-white font-semibold rounded-md'>Contact Us</a>
                <a href='/contactus' className='px-6 py-3 text-black bg-transparent border border-black font-semibold rounded-md'>Write Us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>







  )
}

export default LandDevelopment