import { Fragment, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Carousel } from "@material-tailwind/react";
import './App.css';


import React from "react";
import Slider from "react-slick";

import Logo from './assets/images/logo-locus.png'

import Slider1 from './assets/images/slider1.png'
import Slider2 from './assets/images/slider2.png'

import Ser1 from './assets/images/icons/land.png'
import Ser2 from './assets/images/icons/land-dev.png'
import Ser3 from './assets/images/icons/as-built.png'
import Ser4 from './assets/images/icons/geo.png'
import Ser5 from './assets/images/icons/arch.png'
import Ser6 from './assets/images/icons/design.png'
import Ser7 from './assets/images/icons/3d.png'
import Ser8 from './assets/images/icons/cad.png'
import Ser9 from './assets/images/icons/geo-spec.png'
import Ser10 from './assets/images/icons/bim.png'

import AboutLocus from './assets/images/about-locus.png'
import LandSurvey from './assets/images/land-survey.png'
import LandDevelopment from './assets/images/land-development.png'
import AsBuilt from './assets/images/as-built.png'
import Geomatry from './assets/images/geomatry.png'
import Arch from './assets/images/arch-serv.png'
import DesignServ from './assets/images/design-serv.png'
import D3Visual from './assets/images/3d-visual.png'
import Cad from './assets/images/cad-serv.png'
import Construction from './assets/images/construction.png'
import GeoSpacial from './assets/images/geo-spatial.png'
import Footer from './Footer';
import Header from './Header';
import FormComponent from './FormContent';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const navigation = [
  { name: 'Home', href: '#', current: true },
  { name: 'Services', href: '#', current: false },
  { name: 'Career', href: '#', current: false },
  { name: 'Contact', href: '#', current: false },
]

// const Services = [
//   { name: 'Land Surveying', icon: Ser1, href: '#' },
//   { name: 'Land Developement', icon: Ser2, href: '#' },
//   { name: 'As-Built', icon: Ser3, href: '#' },
//   { name: 'Geometry Design', icon: Ser4, href: '#' },
//   { name: 'Architectural Services', icon: Ser5, href: '#' },
//   { name: 'Designing Services', icon: Ser6, href: '#' },
//   { name: '3D VIsualization', icon: Ser7, href: '#' },
//   { name: 'CAD Services', icon: Ser8, href: '#' },
//   { name: 'Geo Spatial Services', icon: Ser9, href: '#' },
//   { name: 'BIM Services', icon: Ser10, href: '#' },
// ]

const Services = [
  { name: 'Land Surveying', description: 'Get a better understanding of your traffic', href: '/landsurvey', icon: Ser1 },
  { name: 'Land Development', description: 'Speak directly to your customers', href: '/land-development', icon: Ser2 },
  { name: 'As-Built', description: 'Your customers’ data will be safe and secure', href: '/as-built', icon: Ser3 },
  { name: 'Geometry Design', description: 'Connect with third-party tools', href: '/geometry', icon: Ser4 },
  { name: 'Architectural Services', description: 'Build strategic funnels that will convert', href: '/arch-service', icon: Ser5 },
  { name: 'Designing Services', description: 'Build strategic funnels that will convert', href: '/design-service', icon: Ser6 },
  { name: '3D Visualization', description: 'Build strategic funnels that will convert', href: '/3d-Visualization', icon: Ser7 },
  { name: 'Cad Services', description: 'Build strategic funnels that will convert', href: '/cad-services', icon: Ser8 },
  { name: 'Construction Design Services', description: 'Build strategic funnels that will convert', href: '/construction', icon: Ser10 },
  { name: 'Geo Spatial Services', description: 'Build strategic funnels that will convert', href: '/geo-spatial', icon: Ser9 },
  // { name: 'BIM Services', description: 'Build strategic funnels that will convert', href: '/', icon: Ser10 },
]


const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
};



function App() {

  const [isOpen, setIsOpen] = useState(false);

const toggleDiv = () => {
  setIsOpen(!isOpen);
};


  return (
    <div className="App">
      <div className="min-h-full">
        <Header />


        <div className='overflow-hidden'>
          <Slider {...settings}>
            <div className='relative slide1 ' >
              <div className='min-h-[600px] flex items-center w-full justify-start '>
                <div className='max-w-7xl mx-auto  h-full w-full text-white px-4 sm:px-6 lg:px-8'>
                  <h1 className='md:text-5xl text-3xl font-semibold mb-6 md:leading-snug max-w-3xl'>Entrust your CAD tasks to Locus
                    For streamlined outsourcing</h1>
                  <p className='text-lg mb-10'>Exceptional workforce dedicated to delivering top-tier <br />
                    Engineering, Architectural, and Design solutions</p>
                  <a href='/contactus' className="rounded-md bg-green px-10 py-3 text-base font-semibold text-black shadow-sm hover:bg-green focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >Get Started</a>
                </div>
              </div>
              {/* <img src={Slider1} /> */}
            </div>

            <div className='relative slide2 ' >
              <div className='min-h-[600px] flex items-center w-full justify-start'>
                <div className='max-w-7xl mx-auto  h-full w-full text-white px-4 sm:px-6 lg:px-8'>
                  <h1 className='md:text-5xl text-3xl font-semibold mb-6 md:leading-snug max-w-3xl'>Entrust your CAD tasks to Locus
                    For streamlined outsourcing</h1>
                  <p className='text-lg mb-10'>Exceptional workforce dedicated to delivering top-tier <br />
                    Engineering, Architectural, and Design solutions</p>
                  <button className="rounded-md bg-green px-10 py-3 text-base font-semibold text-black shadow-sm hover:bg-green focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >Get Started</button>
                </div>
              </div>
              {/* <img src={Slider1} /> */}
            </div>

          </Slider>
        </div>

        <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-20'>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-10'>
            <div className='text-left col-span-2 pr-14'>
              <h3 className='text-xl font-semibold mb-3'>Explore top-tier land solutions, CAD, and 3D services
                with Locus CAD Works.</h3>
              <p className='text-base leading-relaxed mb-3'>Unlock a comprehensive suite of CAD services, from 2D drafting to 3D modeling, rendering, animation, and Sketch-Up. Our commitment is to deliver unparalleled quality and service at a cost significantly lower than in-house or domestic alternatives. With a team of highly skilled experts, we offer outstanding CAD outsourcing services tailored to your project's size and complexity. Our professionals stay abreast of cutting-edge technology, ensuring efficiency and security through state-of-the-art workstations and software. Contact us today for all your CAD conversion, drafting, and modeling needs or to explore our services and pricing further.</p>

              <a href='/contactus' className="inline-block rounded-md bg-green px-10 py-3 text-sm font-semibold text-black shadow-sm hover:bg-green focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >Contact Us</a>
            </div>
            <div className='grid grid-cols-2 gap-4'>
              <div className='text-left'>
                <h2 className='md:text-7xl text-3xl text-green font-bold'>33+</h2>
                <p className='text-base font-semibold'>Services</p>
              </div>
              <div className='text-left'>
                <h2 className='md:text-7xl text-3xl text-green font-bold'>117+</h2>
                <p className='text-base font-semibold'>Project Delivered</p>
              </div>
              <div className='text-left'>
                <h2 className='md:text-7xl text-3xl text-green font-bold'>20+</h2>
                <p className='text-base font-semibold'>Staff</p>
              </div>
              <div className='text-left'>
                <h2 className='md:text-7xl text-3xl text-green font-bold'>40+</h2>
                <p className='text-base font-semibold'>Clients</p>
              </div>
            </div>
          </div>
        </div>


        <div className='w-full mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div className='grid grid-cols-2 lg:grid-cols-5 md:grid-cols-3 gap-4'>
            <div className='lg:col-span-5 md:col-span-3 col-span-2 text-left'>
              <h4 className='text-xl font-semibold'>Our Services</h4>
            </div>
            {Services.map((item) => (
              <div className='group cursor-pointer'>
                <a href={item.href} className='w-full border border-green group-hover:bg-green flex flex-col items-center justify-center p-4 h-52'>
                  <img src={item.icon} className='w-32 group-hover:brightness-[100] group-hover:contrast-[100] filter' />
                  <p className='text-base font-medium group-hover:text-white text-center'>{item.name}</p>
                </a>
              </div>
            ))}
          </div>
        </div>

        <div className='w-full mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-20'>
          <div className='grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3  gap-10 mb-20'>
            <div className='col-span-1 md:col-span-2  text-left'>
              <h4 className='text-2xl font-semibold mb-4'>About Locus</h4>
              <p className='text-base leading-relaxed'>In Locus, our team comprises experienced professionals and dynamic young engineers, blending experience with innovative energy to deliver optimal solutions. We excel in efficiently managing substantial workloads with precision, leveraging our expertise to streamline processes and reduce turnaround time through the integration of cutting-edge technologies.
                Looking ahead, our forward-thinking approach eliminates the need for repeated visits to study a project. Our company has proactively embraced future technologies, enabling us to provide top-notch products swiftly and cost-effectively.
              </p>
            </div>
            <div className='col-span-1 md:col-span-1'>
              <img src={AboutLocus} className='w-full' />
            </div>
          </div>

          <div className='grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 gap-10 mb-20 '>
            <div className='col-span-1 md:col-span-1'>
              <img src={LandSurvey} className='w-full' />
            </div>
            <div className='col-span-1 md:col-span-2 text-left'>
              <h4 className='text-2xl font-semibold mb-4'>Land Survey</h4>
              <p className='text-base leading-relaxed'>Surveying is the skillful practice of analyzing and comprehending the characteristics both above and below the ground, as well as overhead, in a systematic manner. Our team is seasoned professionals with over a decade of hands-on experience collaborating with surveyors in the field. We seamlessly transition from traditional methodologies to the cutting-edge techniques of the current era. Additionally, our dedicated research team actively contributes to enhancing the quality of survey drawings, ensuring efficiency in the overall surveying process.
              </p>
            </div>
          </div>

          <div className='grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3  gap-10 mb-20'>
            <div className='col-span-1 md:col-span-2 text-left'>
              <h4 className='text-2xl font-semibold mb-4'>Land Developement</h4>
              <p className='text-base leading-relaxed'>The development phase is pivotal, demanding a thorough assessment of potential failures from diverse perspectives. During this stage, functionality takes precedence over aesthetics. Our concentration is on sculpting the land to not only meet future demands but also effectively serve the needs of users.
                Our fundamental approach to development entails visualizing a scenario where modifications to the established development are impractical, expensive, and potentially disastrous. This foresight underscores our commitment to ensuring that the development is not only visually appealing but also sustainable and resilient in the face of potential challenges.

              </p>
            </div>
            <div className='col-span-1 md:col-span-1'>
              <img src={LandDevelopment} className='w-full' />
            </div>
          </div>

          
          {isOpen &&
          <div  className={`content ${isOpen ? 'open' : ''}`}>
            <div className='grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 gap-10 mb-20 '>
              <div className='col-span-1 md:col-span-1'>
                <img src={AsBuilt} className='w-full' />
              </div>
              <div className='col-span-1 md:col-span-2 text-left'>
                <h4 className='text-2xl font-semibold mb-4'>As-Built</h4>
                <p className='text-base leading-relaxed'>To study the constructions that were proposed and before opening the utility to eliminate failure chances while using the study plays the most important role. In this scope we help clients to complete all the as-built drawings using the survey input and overlaying it to what was proposed and show the comparison very simple representation. We have experience in handling bigger subdivision areas of residential drawings and study all the water, sewer and storm lines using client standard complicated templates and layers.
                </p>
              </div>
            </div>

            <div className='grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3  gap-10 mb-20'>
              <div className='col-span-1 md:col-span-2 text-left'>
                <h4 className='text-2xl font-semibold mb-4'>Geometry Design</h4>
                <p className='text-base leading-relaxed'>Most of the subdivision plans of the residential projects of 500 to 1000 acres feeting the lots for different family types and sizes is now simple using cad technique. Still we have to check the geometry as per the road speed criteria for radius of the center-line, Culdisac, easements and all other county requirements.
                  Adding the right of way is one part of the geometry design but adjusting the lots inside the area remaining and open spaces while maintaining the lot width and length is very important as well.

                </p>
              </div>
              <div className='col-span-1 md:col-span-1'>
                <img src={Geomatry} className='w-full' />
              </div>
            </div>

            <div className='grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 gap-10 mb-20 '>
              <div className='col-span-1 md:col-span-1'>
                <img src={Arch} className='w-full' />
              </div>
              <div className='col-span-1 md:col-span-2 text-left'>
                <h4 className='text-2xl font-semibold mb-4'>Architectural Services</h4>
                <p className='text-base leading-relaxed'>Most of the subdivision plans of the residential projects of 500 to 1000 acres feeting the lots for different family types and sizes is now simple using cad technique. Still we have to check the geometry as per the road speed criteria for radius of the center-line, Culdisac, easements and all other county requirements.
                  Adding the right of way is one part of the geometry design but adjusting the lots inside the area remaining and open spaces while maintaining the lot width and length is very important as well.

                </p>
              </div>
            </div>

            <div className='grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3  gap-10 mb-20'>
              <div className='col-span-1 md:col-span-2 text-left'>
                <h4 className='text-2xl font-semibold mb-4'>Design Services</h4>
                <p className='text-base leading-relaxed'>Most of the subdivision plans of the residential projects of 500 to 1000 acres feeting the lots for different family types and sizes is now simple using cad technique. Still we have to check the geometry as per the road speed criteria for radius of the center-line, Culdisac, easements and all other county requirements.
                  Adding the right of way is one part of the geometry design but adjusting the lots inside the area remaining and open spaces while maintaining the lot width and length is very important as well.

                </p>
              </div>
              <div className='col-span-1 md:col-span-1'>
                <img src={DesignServ} className='w-full' />
              </div>
            </div>

            <div className='grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 gap-10 mb-20 '>
              <div className='col-span-1 md:col-span-1'>
                <img src={D3Visual} className='w-full' />
              </div>
              <div className='col-span-1 md:col-span-2 text-left'>
                <h4 className='text-2xl font-semibold mb-4'>3d visualization Services</h4>
                <p className='text-base leading-relaxed'>Most of the subdivision plans of the residential projects of 500 to 1000 acres feeting the lots for different family types and sizes is now simple using cad technique. Still we have to check the geometry as per the road speed criteria for radius of the center-line, Culdisac, easements and all other county requirements.
                  Adding the right of way is one part of the geometry design but adjusting the lots inside the area remaining and open spaces while maintaining the lot width and length is very important as well.

                </p>
              </div>
            </div>

            <div className='grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3  gap-10 mb-20'>
              <div className='col-span-1 md:col-span-2 text-left'>
                <h4 className='text-2xl font-semibold mb-4'>CAD Services</h4>
                <p className='text-base leading-relaxed'>CAD conversions are the bigger part in any type of services so we have expert team in not just converting the drawing with better understanding but also the provide different type of soft versions like Autocad, Brickcad, Microstation, Terramodel, Fusion 360, Solid-works, Sketch up and so on. We have expertise in converting any old documents or raw sketches.
                </p>
              </div>
              <div className='col-span-1 md:col-span-1'>
                <img src={Cad} className='w-full' />
              </div>
            </div>

            <div className='grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 gap-10 mb-20 '>
              <div className='col-span-1 md:col-span-1'>
                <img src={Construction} className='w-full' />
              </div>
              <div className='col-span-1 md:col-span-2 text-left'>
                <h4 className='text-2xl font-semibold mb-4'>Construction Drawing</h4>
                <p className='text-base leading-relaxed'>In the engineering part, understanding the design drawings and sending this to the site in a simple language is very important. In this part of construction we play the role of sending the constructions drawing or help the clients to simplify the staking process to eliminate the errors.
                  We have experience in different sectors of the construction work and their style of not just the stakeout but also create the different types of analysis to study the existing condition as well as to analyze the construction work.

                </p>
              </div>
            </div>

            <div className='grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3  gap-10 mb-20'>
              <div className='col-span-1 md:col-span-2 text-left'>
                <h4 className='text-2xl font-semibold mb-4'>Geo-Spatial Services</h4>
                <p className='text-base leading-relaxed'>This is the future of engineering. We have young talented engineers who know how to handle the point cloud. Using two methods of geospatial one is Arial and another is Lidar we have completed the Existing condition Surveys and Asbuilts of the buildings.
                  We also have expertise in extracting the data from the cloud to use in survey work creating surfaces from the cloud and analysis of any field data.
                  Our team is also expert in handling the raw data  from the drone and lidar. With this data creating the orthomosaic and point cloud using different types of software as per requirement.

                </p>
              </div>
              <div className='col-span-1 md:col-span-1'>
                <img src={GeoSpacial} className='w-full' />
              </div>
            </div>


          </div>
           }

          <div className='w-full text-center mb-14'>
          <button
          className='px-6 py-2 bg-white border border-green text-black rounded-md hover:bg-green hover:text-black'
          onClick={toggleDiv}
        >
          {isOpen ? 'Show Less' : 'Expand More Services'}
        </button>
          </div>

        </div>

        <div className='w-full mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-32 mb-10'>
          <div className='grid grid-cols-1 md:grid-cols-3'>
            {/* <div className='md:mb-0 mb-8'>
              <h4 className='text-xl font-semibold mb-6'>Reach out to us!</h4>
              <div className='border-l border-l-[3px] border-green pl-10 '>
                <p className='mb-2'>info@locuscadworks.com</p>
                <p className='mb-2'>@locuscadworks</p>
                <p className='mb-2'>+ 91 8956444209 </p>
              </div>
            </div> */}
            <div className='md:mb-0 mb-8 mt-10'>
              <div className="w-full px-4">
                <div className="mb-12 max-w-[570px] lg:mb-0">
                  {/* <span className="mb-4 block text-base font-semibold text-primary">
                        Contact Us
                        </span> */}
                  <h2 className="mb-6 text-2xl font-bold uppercase text-dark dark:text-white ">
                    GET IN TOUCH WITH US
                  </h2>
                  <p className="mb-9 text-base leading-relaxed text-body-color dark:text-dark-6">
                    Explore top-tier land solutions, CAD, and 3D services with Locus Consulting Engineers
                  </p>
                  {/* <div className="mb-8 flex w-full max-w-[370px]">
                        <div className="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary/5 text-primary sm:h-[70px] sm:max-w-[70px]">
                            <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="M30.6 11.8002L17.7 3.5002C16.65 2.8502 15.3 2.8502 14.3 3.5002L1.39998 11.8002C0.899983 12.1502 0.749983 12.8502 1.04998 13.3502C1.39998 13.8502 2.09998 14.0002 2.59998 13.7002L3.44998 13.1502V25.8002C3.44998 27.5502 4.84998 28.9502 6.59998 28.9502H25.4C27.15 28.9502 28.55 27.5502 28.55 25.8002V13.1502L29.4 13.7002C29.6 13.8002 29.8 13.9002 30 13.9002C30.35 13.9002 30.75 13.7002 30.95 13.4002C31.3 12.8502 31.15 12.1502 30.6 11.8002ZM13.35 26.7502V18.5002C13.35 18.0002 13.75 17.6002 14.25 17.6002H17.75C18.25 17.6002 18.65 18.0002 18.65 18.5002V26.7502H13.35ZM26.3 25.8002C26.3 26.3002 25.9 26.7002 25.4 26.7002H20.9V18.5002C20.9 16.8002 19.5 15.4002 17.8 15.4002H14.3C12.6 15.4002 11.2 16.8002 11.2 18.5002V26.7502H6.69998C6.19998 26.7502 5.79998 26.3502 5.79998 25.8502V11.7002L15.5 5.4002C15.8 5.2002 16.2 5.2002 16.5 5.4002L26.3 11.7002V25.8002Z"
                                fill="currentColor"
                            />
                            </svg>
                        </div>
                        <div className="w-full">
                            <h4 className="mb-1 text-xl font-bold text-dark dark:text-white">
                            Our Location
                            </h4>
                            <p className="text-base text-body-color dark:text-dark-6">
                            99 S.t Jomblo Park Pekanbaru 28292. Indonesia
                            </p>
                        </div>
                        </div> */}

                  <div className="mb-8 flex w-full max-w-[370px]">
                    <div className="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary/5 text-primary sm:h-[70px] sm:max-w-[70px]">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_941_17577)">
                          <path
                            d="M24.3 31.1499C22.95 31.1499 21.4 30.7999 19.7 30.1499C16.3 28.7999 12.55 26.1999 9.19997 22.8499C5.84997 19.4999 3.24997 15.7499 1.89997 12.2999C0.39997 8.59994 0.54997 5.54994 2.29997 3.84994C2.34997 3.79994 2.44997 3.74994 2.49997 3.69994L6.69997 1.19994C7.74997 0.599942 9.09997 0.899942 9.79997 1.89994L12.75 6.29994C13.45 7.34994 13.15 8.74994 12.15 9.44994L10.35 10.6999C11.65 12.7999 15.35 17.9499 21.25 21.6499L22.35 20.0499C23.2 18.8499 24.55 18.4999 25.65 19.2499L30.05 22.1999C31.05 22.8999 31.35 24.2499 30.75 25.2999L28.25 29.4999C28.2 29.5999 28.15 29.6499 28.1 29.6999C27.2 30.6499 25.9 31.1499 24.3 31.1499ZM3.79997 5.54994C2.84997 6.59994 2.89997 8.74994 3.99997 11.4999C5.24997 14.6499 7.64997 18.0999 10.8 21.2499C13.9 24.3499 17.4 26.7499 20.5 27.9999C23.2 29.0999 25.35 29.1499 26.45 28.1999L28.85 24.0999C28.85 24.0499 28.85 24.0499 28.85 23.9999L24.45 21.0499C24.45 21.0499 24.35 21.0999 24.25 21.2499L23.15 22.8499C22.45 23.8499 21.1 24.1499 20.1 23.4999C13.8 19.5999 9.89997 14.1499 8.49997 11.9499C7.84997 10.8999 8.09997 9.54994 9.09997 8.84994L10.9 7.59994V7.54994L7.94997 3.14994C7.94997 3.09994 7.89997 3.09994 7.84997 3.14994L3.79997 5.54994Z"
                            fill="currentColor"
                          />
                          <path
                            d="M29.3 14.25C28.7 14.25 28.25 13.8 28.2 13.2C27.8 8.15003 23.65 4.10003 18.55 3.75003C17.95 3.70003 17.45 3.20003 17.5 2.55003C17.55 1.95003 18.05 1.45003 18.7 1.50003C24.9 1.90003 29.95 6.80003 30.45 13C30.5 13.6 30.05 14.15 29.4 14.2C29.4 14.25 29.35 14.25 29.3 14.25Z"
                            fill="currentColor"
                          />
                          <path
                            d="M24.35 14.7002C23.8 14.7002 23.3 14.3002 23.25 13.7002C22.95 11.0002 20.85 8.90018 18.15 8.55018C17.55 8.50018 17.1 7.90018 17.15 7.30018C17.2 6.70018 17.8 6.25018 18.4 6.30018C22.15 6.75018 25.05 9.65018 25.5 13.4002C25.55 14.0002 25.15 14.5502 24.5 14.6502C24.4 14.7002 24.35 14.7002 24.35 14.7002Z"
                            fill="currentColor"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_941_17577">
                            <rect width="32" height="32" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="w-full">
                      <h4 className="mb-1 text-xl font-bold text-dark dark:text-white">
                        Phone Number
                      </h4>
                      <a href="tel:+91 8956444209" className="text-base text-body-color dark:text-dark-6">
                      +91 89564 44209
                      </a>
                    </div>
                  </div>

                  <div className="mb-8 flex w-full max-w-[370px]">
                    <div className="mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-primary/5 text-primary sm:h-[70px] sm:max-w-[70px]">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M28 4.7998H3.99998C2.29998 4.7998 0.849976 6.1998 0.849976 7.9498V24.1498C0.849976 25.8498 2.24998 27.2998 3.99998 27.2998H28C29.7 27.2998 31.15 25.8998 31.15 24.1498V7.8998C31.15 6.1998 29.7 4.7998 28 4.7998ZM28 7.0498C28.05 7.0498 28.1 7.0498 28.15 7.0498L16 14.8498L3.84998 7.0498C3.89998 7.0498 3.94998 7.0498 3.99998 7.0498H28ZM28 24.9498H3.99998C3.49998 24.9498 3.09998 24.5498 3.09998 24.0498V9.2498L14.8 16.7498C15.15 16.9998 15.55 17.0998 15.95 17.0998C16.35 17.0998 16.75 16.9998 17.1 16.7498L28.8 9.2498V24.0998C28.9 24.5998 28.5 24.9498 28 24.9498Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                    <div className="w-full">
                      <h4 className="mb-1 text-xl font-bold text-dark dark:text-white">
                        Email Address
                      </h4>
                      <a href="mailto:info@locuscadworks.com" className="text-base text-body-color dark:text-dark-6">
                      info@locuscadworks.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-span-2 md:col-span-2'>
              <div className='w-full md:max-w-[80%] ml-auto bg-[#eaedf3] md:px-10 px-4 py-8'>
                <h4 className='text-2xl font-bold uppercase mb-2'>Write to us!</h4>
                <p className='mb-4'>We will respond to you within one business day.</p>
                {/* <form>
                  <div className='w-full mb-4'>
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      placeholder='Your Name'
                      className="block w-full rounded-md border-0 py-2.5 px-4 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <div className='w-full mb-4'>
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      placeholder='Email'
                      className="block w-full rounded-md border-0 py-2.5 px-4 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <div className='w-full mb-4'>
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      placeholder='Phone Number'
                      className="block w-full rounded-md border-0 py-2.5 px-4 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <div className='w-full mb-4'>
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      placeholder='Company Name'
                      className="block w-full rounded-md border-0 py-2.5 px-4 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <div className='w-full mb-4'>
                    <textarea
                      type="text"
                      name="first-name"
                      rows={3}
                      placeholder='Your Name'
                      className="block w-full rounded-md border-0 py-2.5 px-4 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <div className='w-full mb-4'>
                    <p className='text-sm mb-2'>Attach Document / Files</p>
                    <label className="block bg-white p-4 rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                      <span className="sr-only">Choose profile photo</span>
                      <input type="file" className="block w-full text-sm text-gray-500
                      file:me-4 file:py-2 file:px-4
                      file:rounded-lg file:border-0
                      file:text-sm file:font-semibold
                      file:bg-gray-600 file:text-white
                      hover:file:bg-gray-700
                      file:disabled:opacity-50 file:disabled:pointer-events-none
                      dark:file:bg-gray-500
                      dark:hover:file:bg-gray-400
                    "/>
                    </label>
                  </div>
                  <div className='w-full text-center'>
                    <button className="rounded-md bg-green px-10 py-3 text-sm font-semibold text-black shadow-sm hover:bg-green focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >Submit</button>
                  </div>
                </form> */}

                <FormComponent/>
              </div>
            </div>
          </div>
        </div>

        <Footer />



      </div>
    </div>
  );
}

export default App;
