import React from 'react'
import Header from '../Header'
import DropdownMenu from '../Dropdown'
import LandSurvey2 from '../assets/images/land-survey-2.png'
import Footer from '../Footer'


const ArchService = () => {
  return (
    <div className="min-h-full">
    <Header />
    <div className='w-full ArchitecturalServices relative min-h-[400px]'>
      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex items-center justify-center text-white min-h-[400px]'>
        <h1 className='md:text-7xl text-4xl font-bold z-[1] relative'>Architectural Services</h1>
      </div>
    </div>
    <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-20'>
      <div className='grid md:grid-cols-4 grid-cols-3 gap-6'>
      <div className='md:block hidden'>
        <DropdownMenu/>
        </div>

        <div className='col-span-3'>
          <div className='mb-10'>
            <h2 className='text-3xl font-semibold text-green mb-4'>Architectural Services</h2>
            <p>We offer comprehensive architectural drawing and solution services tailored to your needs. Our expertise lies in transforming visions into tangible, functional, and aesthetically pleasing spaces through meticulous planning and innovative design.
            </p>
          </div>

          <img src={LandSurvey2} alt='' className='mb-6'/>

          <h3 className='text-xl font-semibold mb-5'>Services we provide in Architecture</h3>
          <div className='grid md:grid-cols-3 gap-5 mb-10'>
              <ul className=' list-inside col-span-2'>
                <li className='text-sm leading-loose mb-3'><b>2D Plan and Elevation:</b> We specialize in creating detailed 2D plans and elevations that form the foundation of your architectural project, providing a clear and comprehensive overview of the design layout.</li>
                <li className='text-sm leading-loose mb-3'><b>Working Drawings:</b> Our team meticulously prepares working drawings that outline construction details, dimensions, and specifications, facilitating seamless execution and ensuring precision throughout the building process.</li>
                <li className='text-sm leading-loose mb-3'><b>Technical Drawings:</b> We excel in producing technical drawings that convey critical information such as structural elements, electrical layouts, plumbing systems, and other essential components, essential for effective project coordination and implementation.</li>
                <li className='text-sm leading-loose mb-3'><b>Presentation Layout:</b> We craft visually engaging presentation layouts that effectively communicate design concepts and vision, enabling stakeholders to envision the final outcome and make informed decisions with confidence.</li>
                <li className='text-sm leading-loose mb-3'><b>Floor Plan:</b> Our expertise extends to creating detailed floor plans that optimize spatial organization, functionality, and flow, tailored to meet your specific requirements and enhance occupant experience.</li>
                <li className='text-sm leading-loose mb-3'><b>Site Plan:</b> We develop comprehensive site plans that integrate architectural design with site constraints and environmental considerations, ensuring harmonious integration within the surrounding landscape.</li>
                <li className='text-sm leading-loose mb-3'><b>Isometric, Axonometric View:</b> Utilizing advanced visualization techniques, we generate isometric and axonometric views that offer a three-dimensional perspective of the design, enhancing clarity and understanding of spatial relationships.</li>
                <li className='text-sm leading-loose mb-3'><b>Material Estimation:</b> Our team conducts thorough material estimation to accurately assess quantities and types of materials required for the project, facilitating cost-effective planning and procurement processes.</li>
             
              </ul>

          </div>

        

            <div className='w-full py-10 md:px-20 px-4 bg-green rounded-2xl text-center'>
              <h3 className='text-2xl mb-3 font-semibold'>Have a concept in Mind?</h3>
              <p className='mb-5 text-sm'>Share with us, to bring it in real world!</p>
              <div className='w-full flex items-center justify-center gap-6 '>
                <a href='/contactus' className='px-6 py-3 text-black bg-white font-semibold rounded-md'>Contact Us</a>
                <a href='/contactus' className='px-6 py-3 text-black bg-transparent border border-black font-semibold rounded-md'>Write Us</a>
              </div>
            </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
  )
}

export default ArchService