import React from 'react'
import Header from '../Header'
import DropdownMenu from '../Dropdown'
import LandSurvey2 from '../assets/images/land-survey-2.png'
import Footer from '../Footer'


const GeoSpatial = () => {
  return (
    <div className="min-h-full">
    <Header />
    <div className='w-full GeoSpatial min-h-[400px]'>
      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex items-center justify-center text-black min-h-[400px]'>
        <h1 className='md:text-7xl text-4xl font-bold'>Geo-Spatial Services</h1>
      </div>
    </div>
    <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-20'>
    <div className='grid md:grid-cols-4 grid-cols-3 gap-6'>
      <div className='md:block hidden'>
        <DropdownMenu/>
        </div>

        <div className='col-span-3'>
          <div className='mb-10'>
            <h2 className='text-3xl font-semibold text-green mb-4'>Geo-Spatial Services</h2>
            <p>This is the future of engineering. We have young talented engineers who know how to handle the point cloud. Using two methods of geospatial one is Arial and another is Lidar we have completed the Existing condition Surveys and Asbuilts of the buildings.
We also have expertise in extracting the data from the cloud to use in survey work creating surfaces from the cloud and analysis of any field data.
Our team is also expert in handling the raw data  from the drone and lidar. With this data creating the orthomosaic and point cloud using different types of software as per requirement.  


            </p>
          </div>

          <img src={LandSurvey2} alt='' className='mb-6'/>

          <h3 className='text-xl font-semibold mb-5'>Services we provide in Geo-Spatial Services</h3>
          <div className='grid grid-cols-2 gap-5 mb-10'>
              <ul className='list-disc list-inside'>
                <li className='text-sm leading-loose'>Point cloud Creation</li>
                <li className='text-sm leading-loose'>Ortho Mosaic (GeoTiff)</li>
                <li className='text-sm leading-loose'>Data Extraction from Point cloud</li>
                <li className='text-sm leading-loose'>Aerial Survey (DJI-Tera, Pix 4D mapper)</li>
                <li className='text-sm leading-loose'>LIDAR Survey (Trimble, Lider)</li>
              </ul>

          </div>


        

          <div className='w-full py-10 md:px-20 px-4 bg-green rounded-2xl text-center'>
              <h3 className='text-2xl mb-3 font-semibold'>Have a concept in Mind?</h3>
              <p className='mb-5 text-sm'>Share with us, to bring it in real world!</p>
              <div className='w-full flex items-center justify-center gap-6 '>
                <a href='/contactus' className='px-6 py-3 text-black bg-white font-semibold rounded-md'>Contact Us</a>
                <a href='/contactus' className='px-6 py-3 text-black bg-transparent border border-black font-semibold rounded-md'>Write Us</a>
              </div>
            </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
  )
}

export default GeoSpatial