import React from 'react'
import Header from '../Header'
import DropdownMenu from '../Dropdown'
import LandSurvey2 from '../assets/images/land-survey-2.png'
import Footer from '../Footer'


const ConstructionService = () => {
  return (
    <div className="min-h-full">
    <Header />
    <div className='w-full ConstructionService min-h-[400px]'>
      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex items-center justify-center text-white min-h-[400px]'>
        <h1 className='md:text-7xl text-4xl font-bold'>Construction Drawing Services</h1>
      </div>
    </div>
    <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-20'>
    <div className='grid md:grid-cols-4 grid-cols-3 gap-6'>
      <div className='md:block hidden'>
        <DropdownMenu/>
        </div>

        <div className='col-span-3'>
          <div className='mb-10'>
            <h2 className='text-3xl font-semibold text-green mb-4'>Construction Drawing</h2>
            <p>In the engineering part, understanding the design drawings and sending this to the site in a simple language is very important. In this part of construction we play the role of sending the constructions drawing or help the clients to simplify the staking process to eliminate the errors.
We have experience in different sectors of the construction work and their style of not just the stakeout but also create the different types of analysis to study the existing condition as well as to analyze the construction work.

            </p>
          </div>

          <img src={LandSurvey2} alt='' className='mb-6'/>

          <h3 className='text-xl font-semibold mb-5'>Services we provide in Construction Drawings</h3>
          <div className='grid grid-cols-2 gap-5 mb-10'>
              <ul className='list-disc list-inside'>
                <li className='text-sm leading-loose'>Utility Staking</li>
                <li className='text-sm leading-loose'>Construction Staking</li>
                <li className='text-sm leading-loose'>Foundation Location</li>
                <li className='text-sm leading-loose'>Features Staking (i.e. Curb, Sidewalk, Wall etc.)</li>
                <li className='text-sm leading-loose'>Heat Maps</li>
                <li className='text-sm leading-loose'>Deviation Drawings</li>
                <li className='text-sm leading-loose'>Construction Drawings</li>
                <li className='text-sm leading-loose'>Fence & Wall Staking</li>
              </ul>

          </div>


        

          <div className='w-full py-10 md:px-20 px-4 bg-green rounded-2xl text-center'>
              <h3 className='text-2xl mb-3 font-semibold'>Have a concept in Mind?</h3>
              <p className='mb-5 text-sm'>Share with us, to bring it in real world!</p>
              <div className='w-full flex items-center justify-center gap-6 '>
                <a href='/contactus' className='px-6 py-3 text-black bg-white font-semibold rounded-md'>Contact Us</a>
                <a href='/contactus' className='px-6 py-3 text-black bg-transparent border border-black font-semibold rounded-md'>Write Us</a>
              </div>
            </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
  )
}

export default ConstructionService