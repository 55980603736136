import React, { useState } from 'react'
import Header from '../Header'

import LandSurvey2 from '../assets/images/land-survey-2.png'
import AsBuildSer from '../assets/images/as-build-ser.png'
import Footer from '../Footer'
import DropdownMenu from '../Dropdown'

function AsBuilt() {



  return (
    <div className="min-h-full">
      <Header />
      <div className='w-full AsBuilt min-h-[400px]'>
        <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex items-center justify-center text-white min-h-[400px]'>
          <h1 className='md:text-7xl text-4xl font-bold'>As-Built Services</h1>
        </div>
      </div>
      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-20'>
      <div className='grid md:grid-cols-4 grid-cols-3 gap-6'>
      <div className='md:block hidden'>
        <DropdownMenu/>
        </div>

          <div className='col-span-3'>
            <div className='mb-10'>
              <h2 className='text-3xl font-semibold text-green mb-4'>As-Built</h2>
              <p>To study the constructions that were proposed and before opening the utility to eliminate failure chances while using the study plays the most important role. In this scope we help clients to complete all the as-built drawings using the survey input and overlaying it to what was proposed and show the comparison very simple representation. We have experience in handling bigger subdivision areas of residential drawings and study all the water, sewer and storm lines using client standard complicated templates and layers.
              </p>
            </div>

            <img src={AsBuildSer} alt='' className='mb-6'/>

            <h3 className='text-xl font-semibold mb-5'>Services we provide in As-Built</h3>
            <div className='grid grid-cols-2 gap-5 mb-10'>
                <ul className='list-disc list-inside'>
                  <li className='text-sm leading-loose'>Commercial As-built</li>
                  <li className='text-sm leading-loose'>  Residential As-built</li>
               
                </ul>

            </div>

          

            <div className='w-full py-10 md:px-20 px-4 bg-green rounded-2xl text-center'>
              <h3 className='text-2xl mb-3 font-semibold'>Have a concept in Mind?</h3>
              <p className='mb-5 text-sm'>Share with us, to bring it in real world!</p>
              <div className='w-full flex items-center justify-center gap-6 '>
                <a href='/contactus' className='px-6 py-3 text-black bg-white font-semibold rounded-md'>Contact Us</a>
                <a href='/contactus' className='px-6 py-3 text-black bg-transparent border border-black font-semibold rounded-md'>Write Us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>







  )
}

export default AsBuilt