import React from 'react'
import Header from '../Header'

import LandSurvey2 from '../assets/images/land-survey-2.png'
import Footer from '../Footer'
import DropdownMenu from '../Dropdown'

const DropContent = [
  { name: 'Land Surveying', },
  { name: 'Land Development', },
  { name: 'As-Built', },
  { name: 'Geometry Design', },
  { name: 'Architectural Services', },
  { name: ' Design Services', },
  { name: ' 3D Visualization', },
  { name: '  CAD Services', },
  { name: '  Construction Drawing', },
  { name: '  Geo-Spatial Services',},
  { name: '  BIM Services ',},

]


function LandSurvey() {
  return (
    <div className="min-h-full">
      <Header />
      <div className='w-full LandSurvey min-h-[400px]'>
        <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex items-center justify-center text-white min-h-[400px]'>
          <h1 className='md:text-7xl text-4xl font-bold'>Land Surveying Services</h1>
        </div>
      </div>
      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-20'>
      <div className='grid md:grid-cols-4 grid-cols-3 gap-6'>
      <div className='md:block hidden'>
        <DropdownMenu/>
        </div>

          <div className='col-span-3'>
            <div className='mb-10'>
              <h2 className='text-3xl font-semibold text-green mb-4'>Land Surveying Services</h2>
              <p>Surveying is the skillful practice of analyzing and comprehending the characteristics both above and below the ground, as well as overhead, in a systematic manner. Our team is seasoned professionals with over a decade of hands-on experience collaborating with surveyors in the field. We seamlessly transition from traditional methodologies to the cutting-edge techniques of the current era. Additionally, our dedicated research team actively contributes to enhancing the quality of survey drawings, ensuring efficiency in the overall surveying process.
              </p>
            </div>
            <div className='mb-10'>
            <h3 className='text-xl font-medium'>How Does It Work?</h3>
            <ol className='list-decimal list-inside'>
              <li>Study of the property and its documents.</li>
              <li> Placement of boundary using different techniques boundary resolutions</li>
              <li> Drafting of all features exists on the site.</li>
              <li> Template setup and labeling with final product delivery in CAD and PDF format.</li>
            </ol>
            </div>

            <img src={LandSurvey2} alt='' className='mb-6'/>

            <h3 className='text-xl font-semibold mb-5'>Expertise in different task</h3>
            <div className='grid grid-cols-2 gap-5 mb-10'>
                <ul className='list-disc list-inside'>
                  <li className='text-sm leading-loose'>Arial & Lidar projects</li>
                  <li className='text-sm leading-loose'>  Working on cloud for feature extraction</li>
                  <li className='text-sm leading-loose'> Working on Tin surface for topography </li>
                  <li className='text-sm leading-loose'>  Designing of template</li>
                  <li className='text-sm leading-loose'> Boundary resolution</li>
                </ul>

                <ul className='list-disc list-inside'>
                  <li className='text-sm leading-loose'>Exception study</li>
                  <li className='text-sm leading-loose'> Drafting all site features using survey points.</li>
                  <li className='text-sm leading-loose'> Template & Sheet Setup </li>
                  <li className='text-sm leading-loose'> Civil 3D tool</li>
                  <p className='text-sm leading-loose'> And continuously counting . . .</p>
                </ul>
            </div>

            <h3 className='text-xl font-semibold mb-5'>Different type of survey we already know</h3>
            <div className='grid grid-cols-2 gap-5 mb-10'>
                <ul className='list-disc list-inside'>
                  <li className='text-sm leading-loose'>Location Survey</li>
                  <li className='text-sm leading-loose'>  Title / Mortgage Survey</li>
                  <li className='text-sm leading-loose'> ALTA/NSPS Survey </li>
                  <li className='text-sm leading-loose'> Topographic Survey</li>
                  <li className='text-sm leading-loose'> Right of way Survey</li>
                </ul>

                <ul className='list-disc list-inside'>
                  <li className='text-sm leading-loose'>Utility Survey  </li>
                  <li className='text-sm leading-loose'> Corner Record Survey</li>
                  <li className='text-sm leading-loose'> Tide-line/Hydraulic Survey </li>
                  <li className='text-sm leading-loose'> Boundary Survey</li>
                  <p className='text-sm leading-loose'> And continuously counting . . .</p>
                </ul>
            </div>

            <h3 className='text-xl font-semibold mb-5'>Required Inputs</h3>
            <p className='text-base'>Following are the input we require to complete different type of land survey:</p>
            <div className='grid grid-cols-1 gap-5 mb-10'>
                <ul className='list-disc list-inside'>
                  <li className='text-sm leading-loose'>Point Cloud(LAS, TDX, LAZ)	</li>
                  <li className='text-sm leading-loose'>  Geo-tiff/Orthographic (Orthographic Tiles of less than 1GB TIFF)</li>
                  <li className='text-sm leading-loose'> Field Survey Point(CSV,TXT)  </li>
                  <li className='text-sm leading-loose'> Field Sketch	</li>
                  <li className='text-sm leading-loose'> Field Photos</li>
                  <li className='text-sm leading-loose'> 3D Image</li>
                  <li className='text-sm leading-loose'> Plat Map</li>
                  <li className='text-sm leading-loose'> Tax Map</li>
                  <li className='text-sm leading-loose'> Deed		Title Commitment</li>
                  <li className='text-sm leading-loose'> Exception Documents	</li>
                  <li className='text-sm leading-loose'> Legal Descriptions	</li>
                  <li className='text-sm leading-loose'> Right of way Maps	</li>
                  <li className='text-sm leading-loose'> Hydro-logical data (Shape files)	</li>
                  <li className='text-sm leading-loose'> Boundary Calc (DXF,DWG) </li>
                  <li className='text-sm leading-loose'> 	Lot Details </li>
                </ul>


            </div>

            <div className='w-full py-10 md:px-20 px-4 bg-green rounded-2xl text-center'>
              <h3 className='text-2xl mb-3 font-semibold'>Have a concept in Mind?</h3>
              <p className='mb-5 text-sm'>Share with us, to bring it in real world!</p>
              <div className='w-full flex items-center justify-center gap-6 '>
                <a href='/contactus' className='px-6 py-3 text-black bg-white font-semibold rounded-md'>Contact Us</a>
                <a href='/contactus' className='px-6 py-3 text-black bg-transparent border border-black font-semibold rounded-md'>Write Us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>







  )
}

export default LandSurvey